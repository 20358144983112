.contact-form {
    background-color: var(--primary);
    h2 {
        color: #000;
        font-size: 50px;
        font-weight: 700;
    }
    p {
        font-size: 26px;
        font-weight: 500;
        color: #000;
    }
    .contact-box {
        background-color: #1A1A1A;
        border-radius: 25px;
    }
    .field {
        width: 100%;
        border: 1px solid #EAEAEA;
        color: #fff;
        background-color: transparent;
        border-radius: 10px;
        padding: 8px 10px;
    }
    ::placeholder {
        color: #fff;
    }
    .MuiSvgIcon-root {
        color: #fff;
    }
    .Mui-checked {
        .MuiSvgIcon-root {
            color: var(--primary);
        }
    }
    .error{
        font-size: 15px;
        color: red;
    }
    .error2 {
        padding-top: 10px;
        font-size: 20px;
        font-weight: 600;
        color: red;
    }
}

@media only screen and (max-width: 600px) { 
    .contact-form h2 {
        font-size: 28px;
        text-align: center;
        padding-top: 20px;
    }
    .contact-form p {
        font-size: 18px;
        text-align: center;
    }
}