.draft {
    .draft-hero {
        height: 100vh;
        background-image: url(/assets/draft-hero.jpg);
        background-size: cover;
        align-items: center;
        color: #fff;
        p {
            font-size: 20px;
        }
        .time-box {
            border: 2px solid var(--primary);
            border-radius: 10px;
            padding: 15px 20px;
            min-width: 120px;
        
            .count {
                font-size: 40px;
                color: var(--primary);
                font-weight: 700;
            }
        
            .title {
                font-size: 20px;
                color: #fff;
                font-weight: 500;
            }
        }
        .colon {
            font-size: 80px;
            font-family: auto;
        }
    }
    .key-draft {
        .key-icon {
            max-width: 130px;
        }
        h3 {
            font-size: 28px;
            font-weight: 600;
        }
        .date {
            font-size: 60px;
            color: var(--primary);
            font-weight: 700;
        }
    }

    .eligible-athletes{
        .custom-tabs {
            a {
                display: inline-block;
                border-radius: 30px;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                text-decoration: none;
                padding: 6px 15px;
                border: 1px solid #fff;
                margin: 0 4px;
                cursor: pointer;
            }
            a.active {
                background-color: #fff;
                color: #000;
            }
        }
        .MuiTableCell-head {
            color: var(--primary);
            font-size: 18px;
            font-weight: 600;
            background-color: #202020;
        }
    }

    .for-athletes{
        background-image: url(/assets/for-athletes-bg.jpg);
        background-size: cover;
        min-height: 500px;
        p {
            font-size: 20px;
        }
    }
}