.fighters {
  padding-top: 100px;
  .title-scetion {
    h1 {
      font-size: 90px;
    }
    .tags {
      font-size: 20px;
      .league-tags {
        text-decoration: none;
        cursor: pointer;
        color: #ffffff;
      }
    }
    .text1 {
      font-size: 40px;
      font-weight: 500;
    }
  }
  .button {
    margin-right: 10px;
    background-color: transparent !important;
    border-radius: 30px;
    border-color: #fff !important;
    color: #fff;
  
    &.active {
      background-color: #c6a566 !important;
      border-color: #c6a566 !important;
    }
  }
  .fighrer-card {
    img{
      border: 1px solid var(--primary);
    }
  }
}


@media only screen and (max-width: 600px) {
  .fighters .title-scetion h1 {
    font-size: 40px;
  }
  .fighters .title-scetion .text1 {
    font-size: 18px;
  }
  .fighters .fighrer-card img {
    margin-bottom: 20px;
  }
}
