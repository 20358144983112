.media {
  padding-top: 150px;
  min-height: 800px;
  font-family: "Rajdhani", sans-serif !important;
  h1 {
    font-size: 80px;
  }
  .pr-card {
    font-family: "Rajdhani", sans-serif !important;
    h3 {
      font-size: 60px;
    }
    p {
      font-size: 26px;
    }
    .pr-img {
      background-color: #363636;
      padding: 20px;
    }
    .date {
      color: #929292;
      font-size: 20px;
    }
    .tag {
      border-radius: 30px;
      background-color: var(--primary);
      padding: 2px 10px;
      font-size: 14px;
      display: inline-block;
      color: #000;
    }
    .read-more {
      font-size: 18px;
      font-weight: 500;
      border: 1px solid var(--primary);
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }

  .news-card {
    background-color: #1a1a1a;
    font-family: "Rajdhani", sans-serif !important;
    border: 1px solid transparent;
    h3 {
      font-size: 30px;
    }
    .date {
      color: #929292;
      font-size: 20px;
    }
    .news-img {
      background-color: #d9d9d9;
      height: 100%;
      min-height: 200px;
      border: 1px solid var(--primary);
      img {
        max-width: 70%;
        max-height: 70%;
      }
    }
    .read-more {
      font-size: 18px;
      font-weight: 500;
      border: 1px solid var(--primary);
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  .news-card:hover {
    border: 1px solid var(--primary);
  }
  .video {
    height: 360px;
    background-color: #363636;
    padding: 10px;
  }
  .live-video {
    // max-height: 720px;
    // max-width: 1280px;
    // min-height: 315px;
    // min-width: 560px;
    height: 576px;
    width: 1024px;
  }
  .followus {
    a {
      margin-right: 5px;
      img {
        width: 40px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .media {
    padding-top: 100px;
  }
  .media h1 {
    font-size: 40px;
  }
  .media h2 {
    font-size: 30px;
  }
  .media .pr-card h3 {
    font-size: 30px;
  }
  .media .pr-card p {
    font-size: 18px;
  }
  .media .news-card h3 {
    font-size: 26px;
    padding-top: 20px;
  }
  .media .video {
    height: 100%;
    padding: 5px;
    margin-bottom: 60px;
  }
  .media .live-video {
    height: 197px;
    width: 350px;
    // max-height: 315px;
    // max-width: 560px;
    // min-height: 315px;
    // min-width: 560px;
  }
}

@media only screen and (max-width: 400px) {
  .media .video {
    height: 100%;
    padding: 5px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 350px) {
  .media .video {
    height: 100%;
    padding: 5px;
    margin-bottom: 30px;
  }
}
