.gfl-arena-hero {
    height: 100vh;
    background-image: url(/assets/gfl-arena-hero.jpg);
    background-size: cover;
    background-position: center bottom;
    color: #fff;
}

.gfl-arena {
    .section-box {
        background: #2b2820;
        border-radius: 30px;
    }
    .section-box2 {
        background: var(--primary);
        border-radius: 30px;
        color: #000;
        h3 {
            font-weight: 600;
        }
        p {
            font-size: 18px;
            font-weight: 500;
        }
    }
    .web-img {
    }
    .mobile-img {
        display: none;
    }
}

.get-ready {
    .why-box {
        background: #2B2820;
        border-radius: 20px;
        h3 {
            font-weight: 600;
        }
        .why-icon {
            max-width: 120px;
        }
    }
}

.signup-box {
    background: #2B2820;
    box-shadow: 0 2px 4px 0 rgba(255,255,255,0.50);
    border-radius: 30px;
    .btn-primary {
        min-width: 150px;
    }
}

@media only screen and (max-width: 600px) {
    .gfl-arena-hero {
        height: 400px;
        background-position: bottom;
        h1 {
            font-size: 20px;
        }
    }
    .gfl-arena {
        h2 {
            font-size: 20px;
        }
        .web-img {
            display: none;
        }
        .mobile-img {
            display: block;
        }
        .section-box2 {
            border-radius: 10px;
            h3 {
                font-size: 18px;
            }
            p {
                font-size: 14px;
            }
        }
        .btn-primary, .btn-outline-primary {
            font-size: 12px;
          }
    }
    .signup-box {
        border-radius: 15px;
    }
}
