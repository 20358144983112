.privacy-policy {
    padding-top: 150px;
    h1 {
        font-size: 80px;
    }
    h2 {
        font-size: 28px;
    }
    a {
        color: var(--primary);
    }
}

@media only screen and (max-width: 600px) {
    .privacy-policy h1 {
        font-size: 36px;
    }

    .privacy-policy h2 {
        font-size: 20px;
    }

 }