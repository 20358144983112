.team-managemnt {
    width: 500px;
}

.team-heading1 {
    span {
        background-color: #000;
        padding: 0 15px;
    }
}

.team-heading1::before {
    content:"";
    border-top: 1px solid var(--primary);
    width: 100%;
    position: absolute;
    left: 0;
    top: 24px;
    z-index: -1;
}

.team-roster{
    background-position: center top;
    background-size: cover;
    padding: 30px 15px;
}

@media only screen and (max-width: 600px) {
    .team-managemnt {
        width: 100%;
    }
    
 }