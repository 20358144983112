.news {
    padding-top: 100px;
    .title-scetion {
        h1 {
            font-size: 120px;
        }
    }
    .news-box {
        background: #202020;
        border: 1px solid #C6A566;
        border-radius: 10px;
    }
    h4 {
        font-weight: 600;
    }
    .readmore {
        font-size: 18px;
        color: var(--primary);
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
    }
    .date-dot {
        background: #949494;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
    }
    .date-text {
        color: #D2D2D2;
        font-size: 14px;
    }
}