.time-box {
    border: 2px solid var(--primary);
    border-radius: 10px;
    padding: 20px 30px;
    min-width: 150px;

    .count {
        font-size: 50px;
        color: var(--primary);
        font-weight: 700;
    }

    .title {
        font-size: 24px;
        color: #fff;
        font-weight: 500;
    }
}

.colon {
    font-size: 100px;
    font-family: auto;
}

@media only screen and (max-width: 600px) {
    .time-box {
        padding: 10px 5px;
        min-width: 70px;
    
        .count {
            font-size: 25px;
        }
    
        .title {
            font-size: 14px;
        }
    }
    
    .colon {
        font-size: 50px;
        font-family: auto;
    }
  }