.about-us {
    .bg-img {
        padding-top: 150px;
        // background-image: url(/assets/about-us-bg.jpg);
        background-size: cover;
        background-position: center center;
        // min-height: 90vh;
        padding-bottom: 80px;
    }
    h1 {
        font-size: 80px;
    }
    p {
        font-size: 24px;
    }
    h2 {
        font-weight: 700;
    }
    .team-designation {
        color: #848484;
        font-size: 26px;
    }
}

@media only screen and (max-width: 600px) {
    .about-us p {
        font-size: 16px;
    }
    .about-us h1 {
        font-size: 40px;
    }
    .about-us h2 {
        font-size: 24px;
    }
    .about-us .team-designation {
        font-size: 20px;
    }
}