.home-hero {
    height: 100vh;
    // background-image: url(/assets/home-hero.jpg);
    // background-size: cover;
    // background-position-y: 17%;
    // background-position-x: right;
    align-items: center;
    color: #fff;
    p {
        font-size: 24px;
        font-weight: 600;
        color: #ffffffd4;
    }
    .btn-primary, .btn-primary:hover, .btn-primary:active {
        font-size: 20px;
        height: 60px;
    }
    .gfl-draft {
        h1 {
            color: #fff;
            font-size: 35px;
            font-weight: 700;
        }
        p {
            font-size: 20px;
        }
        .time-box {
            border: 2px solid var(--primary);
            border-radius: 10px;
            padding: 15px 20px;
            min-width: 120px;
        }
        .colon {
            font-size: 80px;
            font-family: auto;
        }
        .count {
            font-size: 40px;
            color: var(--primary);
            font-weight: 700;
        }
        .title {
            font-size: 20px;
            color: #fff;
            font-weight: 500;
        }
    }
}

.home-hero2 {
    height: 100vh;
    background-image: url(/assets/home-hero.png);
    background-size: cover;
    background-position-y: 17%;
    background-position-x: right;
    align-items: center;
    color: #fff;
    p {
        font-size: 24px;
        font-weight: 600;
        color: #ffffffd4;
    }
    .btn-primary, .btn-primary:hover, .btn-primary:active {
        font-size: 20px;
        height: 60px;
    }
}

.introducing {
    h2 {
        font-size: 50px;
    }
    h3 {
        font-size: 30px;
        font-weight: 700;
    }
    .sub-text {
        font-size: 26px;
    }
    .section-box {
        border-radius: 15px;
        border: 2px solid var(--primary);
        img {
            border-radius: 15px;
        }
        .section-title {
            background-color: var(--primary);
            padding: 10px 20px;
            position: absolute;
            left: 0;
            bottom: 0;
            color: #000;
            font-size: 24px;
            font-weight: 600;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
}

.map-view {
    p {
        font-size: 24px;
        font-weight: 500;
    }
    .btn-primary, .btn-primary:hover, .btn-primary:active {
        font-size: 20px;
        height: 60px;
        min-width: 180px;
    }
}
.map-icons {
    background-image: url(/assets/map-icons.png);
    background-size: contain;
    height: 95px;
    background-repeat: repeat-x;
    margin-bottom: 60px;
}

.gfl-draft {
    h2 {
        color: #fff;
        font-size: 50px;
        font-weight: 700;
    }
    p {
        font-size: 26px;
    }
}

.fighter-list {
    h2 {
        font-size: 50px;
    }
    h3 {
        font-size: 36px;
        font-weight: 700;
    }
    .sub-text {
        font-size: 26px;
    }
    .fighter-box {
        padding: 0 20px;
        figure {
            overflow: hidden;
            margin: 0;
            img {
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
            }
        }
        figure:hover img {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        .fighter-details {
            font-weight: 700;
            a {
                text-decoration: none;
            }
        }
        .figher-name {
            color: #fff;
            font-size: 28px;
            font-weight: 700;
            padding-top: 20px;
        }
    }
    .btn-primary, .btn-primary:hover, .btn-primary:active {
        font-size: 20px;
        height: 60px;
        min-width: 150px;
    }
}

@media only screen and (max-width: 600px) {
    .home-hero {
        background-position: bottom;
        height: auto;
        padding-top: 80px;
    }
    .home-hero2 {
        background-position: bottom;
        height: auto;
        padding-top: 80px;
    }
    .home-hero p, .home-hero2 p {
        font-size: 16px;
    }
    .home-hero .btn-primary, .home-hero .btn-primary:hover, .home-hero .btn-primary:active, .home-hero2 .btn-primary, .home-hero2 .btn-primary:hover, .home-hero2 .btn-primary:active {
        font-size: 16px;
        height: 40px;
    }
    .fighter-list .btn-primary, .fighter-list .btn-primary:hover, .fighter-list .btn-primary:active {
        font-size: 16px;
        height: 40px;
    }
    .introducing h2 {
        font-size: 30px;
    }
    .introducing .sub-text {
        font-size: 20px;
    }
    .map-view p {
        font-size: 20px;
        font-weight: 500;
    }
    .map-view .btn-primary, .map-view .btn-primary:hover, .map-view .btn-primary:active {
        font-size: 16px;
        height: 40px;
    }
    .fighter-list h2 {
        font-size: 30px;
    }
    .fighter-list .sub-text {
        font-size: 20px;
    }
    .fighter-list h3 {
        font-size: 22px;
    }
    .introducing h3 {
        font-size: 20px;
    }
    .introducing .section-box {
        margin-bottom: 20px;
    }
    .introducing .section-box .section-title {
        font-size: 14px;
        padding: 5px 10px;
    }
    .gfl-draft h2 {
        font-size: 26px;
    }
    .gfl-draft p {
        font-size: 16px;
    }
    .home-hero .gfl-draft .time-box {
        padding: 10px 5px;
        min-width: 70px;
    }
    .home-hero .gfl-draft .colon{
        font-size: 50px;
        font-family: auto;
    }
    .home-hero .gfl-draft .count {
        font-size: 25px;
    }
    .home-hero .gfl-draft .title {
        font-size: 14px;
    }
 }


 .mobile-container {
    position: relative;
    width: 100%;
    height: 100%; 
    overflow: hidden;
  }
  
  .mobile-container img,
  .mobile-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
  .mobile-container img{
    z-index: 1;
  }
  .mobile-container button{
    z-index: 1;
  }
  .mobile-container video{
    z-index: 2;
    opacity: 1;
  }




 .hover-container {
    position: relative;
    width: 100%; /* Matches parent dimensions */
    height: 100%; /* Matches parent dimensions */
    overflow: hidden;
  }
  
  .hover-container img,
  .hover-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
  .hover-container img{
    z-index: 1;
  }
  .hover-container button{
    z-index: 1;
  }
  .hover-container video{
    z-index: 2;
    opacity: 1;
  }

  .hover-container img {
    z-index: 1;
  }
  
  .hover-container video {
    z-index: 0;
    opacity: 0;
  }
  
  .hover-container:hover video {
    opacity: 1;
    z-index: 2;
  }
  
  .hover-container:hover img {
    opacity: 0;
  }