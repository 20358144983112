@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");
@import "./theme.scss";

body {
  margin: 0;
  font-family: "Rajdhani", sans-serif !important;
  background-color: var(--black);
  color: #fff;
  background-color: #000;
  font-size: 18px !important;
}

a {
  color: var(--primary);
}

p {
  font-size: 18px;
}

.primary-color {
  color: var(--primary);
}

h1 {
  font-weight: 700;
}

h2 {
  font-size: 38px;
  font-weight: 700;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #000;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 5;
  font-weight: 600;
  font-size: 18px;
  background-color: transparent !important;
}

.navbar {
  background-color: transparent !important;
}

.nav-scrolled {
  background-color: #000 !important;
}

.dashed-line {
  height: 2px;
  border-top: 1px dashed #fff;
}

.featured-teams {
  .team {
    // height: 400px;
    // background-image: url(/assets/los-angeles.jpg);
    // background-size: cover;
    // background-position: center;
  }
  .team:hover {
    border: 1px solid var(--primary);
  }
  // .team::before {
  //   content: ""; // ::before and ::after both require content
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #000;
  //   opacity: 0.2;
  // }
  .title {
    font-size: 30px;
    color: #fff;
    font-weight: 600;
  }

  .city {
    height: 800px;
    // background-image: url(/assets/los-angeles.jpg);
    background-size: cover;
    background-position: center;
  }
  .city:hover {
    border: 1px solid var(--primary);
  }
  .city::before {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.2;
  }
  .title {
    font-size: 30px;
    color: #fff;
    font-weight: 600;
  }
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #000;
  font-size: 30px !important;
  border: 2px solid #000;
  border-radius: 50%;
}

.draft-application-form {
  h1 {
    font-size: 100px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
  }
  .contact-box {
    background-color: #1a1a1a;
    border-radius: 25px;
  }
  .field {
    width: 100%;
    border: 1px solid #eaeaea;
    color: #fff;
    background-color: transparent;
    border-radius: 10px;
    padding: 8px 10px;
  }
  ::placeholder {
    color: #fff;
  }
  .MuiSvgIcon-root {
    color: #fff;
  }
  .Mui-checked {
    .MuiSvgIcon-root {
      color: var(--primary);
    }
  }
  .error {
    font-size: 15px;
    color: red;
  }
  .error2 {
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: red;
  }
}

.contactus {
  padding-top: 120px;
  min-height: calc(100vh - 153px);
}

.MuiTabs-flexContainer .MuiTab-root {
  color: #fff !important;
  font-size: 25px !important;
  text-transform: none !important;
  margin-right: 13px !important;
  text-transform: uppercase !important;
  font-family: "Rajdhani", sans-serif !important;
  font-weight: 700 !important;
  background-image: linear-gradient(180deg, #3E3E3E 0%, #000000 100%)!important;
  border: 1px solid #484848!important;
}

.MuiTabs-flexContainer .Mui-selected {
  background-image: linear-gradient(180deg, #000000 0%, #C6A566 100%)!important;
border: 1px solid #C6A566!important;
}

.MuiTabPanel-root {
  padding: 50px 0 24px 0 !important;
}

.coming-page {
  min-height: 100vh;
  .logo {
    width: 250px;
  }
  h1 {
    font-size: 80px;
  }
  .sub-text {
    font-size: 22px;
  }
  .count-box {
    border: 2px solid var(--primary);
    border-radius: 10px;
    padding: 20px 30px;
    min-width: 150px;
    margin: 0 10px;

    .count {
      font-size: 50px;
      color: var(--primary);
      font-weight: 700;
    }

    .title {
      font-size: 24px;
      color: #fff;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 30px;
  }
  .introducing .btn-primary,
  .introducing .btn-outline-primary {
    font-size: 12px;
  }
  .featured-teams .city {
    margin-bottom: 20px;
  }
  .featured-teams .title {
    font-size: 20px;
  }
  .draft-application-form h1 {
    font-size: 36px;
  }
  .draft-application-form p {
    font-size: 16px;
  }
  .coming-page {
    h1 {
      font-size: 40px;
    }
    .count-box {
      padding: 10px 5px;
      min-width: 70px;

      .count {
        font-size: 25px;
      }

      .title {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .media .MuiTabs-flexContainer {
    justify-content: center;
  }
 }