.nav-link {
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    text-transform: uppercase;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.nav-link:hover {
    color: var(--primary) !important;
}
.navSeleted {
    color: var(--primary) !important;
}
.navbar {
    .container {
        background-color: #00000070;
        border-radius: 5px;
    }
}

.navbar-toggler {
    border: 2px solid var(--primary) !important;
    padding: 2px 8px !important;
    color: rgba(255, 54, 54, 0) !important;
}

@media only screen and (max-width: 600px) { 

    .navbar-brand img {
        height: 50px;
    }
}